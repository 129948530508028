<template>
  <div :key="error">
    <div v-if="error" class="flix-alert flix-alert-danger">{{ $tc('message.noEntry', 2) }}</div>
    <div v-if="!error && $store.state.shares.shares && $store.state.shares.shares[$route.params.ressource]">
      <transition name="flixFadeIn">
        <div :key="updateKey" v-if="updateKey">
          <div class="flix-form-group">
            <h3 class="flix-html-h3" :style="{color: $store.state.shares.shares[$route.params.ressource].info.ressource.color}">{{ $store.state.shares.shares[$route.params.ressource].info.ressource.title }}</h3>
            <h2 class="flix-html-h2" :style="{color: $store.state.shares.shares[$route.params.ressource].info.ressource.color}">{{ $store.state.shares.shares[$route.params.ressource].calendar[$route.params.calendar].label }}</h2>
            <span :style="{color: $store.state.shares.shares[$route.params.ressource].info.ressource.color}"><flixIcon :id="'user'" /> {{ $store.state.shares.shares[$route.params.ressource].info.username }}</span>
          </div>
          <nextAppointments v-if="$store.getters.getShareCalendar.calendar.value" :filter="$store.getters.getShareCalendar.calendar.value" />
          <div class="flix-row">
            <div class="flix-col-md-12">
              <div class="flix-form-group">
              </div>
            </div>
            <div class="flix-col-md-12">
              <div class="flix-form-group">
                <flixExpand :settings="{bottomButton: false}">
                  <template v-slot:button>
                    <flixIcon :id="'group'"/> {{ $tc('message.customer', 2) }}
                  </template>
                  <template v-slot:expand>
                    <filterData :filter="filter" :key="filter + 'filter'" :setFilter="function (ret) { filter = ret }" />
                    <crmTable :key="filter + 'crm'" :filter="filter" :id="$store.getters.getShareCalendar.calendar.value" :callback="function () {}" />
                  </template>
                </flixExpand>
              </div>
            </div>
            <div class="flix-col-md-12">
              <div class="flix-form-group">
              </div>
              <div class="flix-form-group">
                <flixExpand :settings="{bottomButton: false}">
                  <template v-slot:button>
                    <flixIcon :id="'globe'"/> {{ $tc('message.pageBuilder', 1) }} {{ $t('message.embed') }}
                  </template>
                  <template v-slot:expand>
                    <div class="flix-form-group">
                      <h3 class="flix-html-h3">{{ $t('message.embed') }}</h3>
                    </div>
                    <div class="flix-form-group">
                      <embedCode :id="$store.getters.getShareCalendar.calendar.value" />
                    </div>
                  </template>
                </flixExpand>
              </div>
              <div class="flix-form-group">
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    embedCode () { return import('@/components/embed/menu') },
    crmTable () { return import('@/components/crm/table') },
    filterData () { return import('@/components/crm/filterData') },
    nextAppointments () { return import('@/components/overview/') }
  },
  props: {},
  data () {
    return {
      updateKey: '',
      filter: 'today',
      error: false
    }
  },
  watch: {
    '$store.state.shares.shares' () { this.setActiveShare() },
    '$route.params.ressource' () { this.setActiveShare() },
    '$route.params.calendar' () { this.setActiveShare() }
  },
  methods: {
    setStoreAssistents () {
      var calendar = this.$store.getters.getShareCalendar.calendar
      this.error = false
      if (typeof calendar === 'undefined' || typeof calendar.value === 'undefined') {
        this.error = true
        return false
      }
      if (typeof localStorage.getItem('flix_login_share_email') === 'string') {
        var email = JSON.parse(atob(localStorage.getItem('flix_login_share_email')))
        this.$store.commit('setSharesUser', { data: this.$store.getters.getShareCalendar.info, email: email.email })
      }

      this.$store.commit('setAssistents',
        [
          {
            ID: calendar.value,
            title: calendar.label
          }
        ]
      )
      this.$store.commit('deleteBookings')
      this.$store.dispatch('getBookings', function () {
        this.updateKey = new Date().getTime()
      }.bind(this))
    },
    setActiveShare () {
      this.updateKey = ''
      this.$store.commit('setActiveShare', [
        [this.$route.params.calendar, this.$store.state.shares.shares[this.$route.params.ressource]],
        function () {
          // this.updateKey = new Date().getTime()
          this.setStoreAssistents()
        }.bind(this)
      ])
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-html-h3
    margin-bottom: 0
  .flix-html-h2
    margin-top: 0
    margin-bottom: 5px
</style>
